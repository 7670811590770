"use client";

import { useEffect } from "react";

import { LoadingCircle, NoSsrWrapper } from "@shared/ui";
import { useIsPublic } from "@shared/hooks";

import { useUserStore } from "@entities/user";

import { SideMenu } from "@widgets/sideMenu";

import { useRouter, usePathname } from "next/navigation";

export default function Template({ children }: { children: React.ReactNode }) {
  const [userLoaded, userSignedIn, setUser, refreshSubscriptionData, userId] = useUserStore((state) => [
    state.loaded,
    state.signedIn,
    state.setUser,
    state.refreshSubscriptionData,
    state.id,
  ]);
  const pageIsPublic = useIsPublic();
  const router = useRouter();
  const urlPath = usePathname();

  useEffect(() => {
    const fpAuthStatusHandler = async (e: Event) => {
      const flipPayUserDetails = (e as unknown as { detail: FlipPayUser | null }).detail;
      if (!flipPayUserDetails) {
        setUser({
          loaded: true,
          signedIn: false,
        });
      } else {
        setUser({
          loaded: true,
          signedIn: true,
          id: flipPayUserDetails.user_id,
          userName: flipPayUserDetails.user_name,
          userEmail: flipPayUserDetails.user_email,
        });
      }
    };
    document.addEventListener("fpAuthStatus", fpAuthStatusHandler);

    const fpUserEventHandler = async (e: Event) => {
      const event_type = (e as unknown as { detail: { event_type: string } | null }).detail?.event_type;
      if (event_type === "purchased") {
        refreshSubscriptionData();
      }
    };
    document.addEventListener("fp_user_event", fpUserEventHandler);

    return () => {
      document.removeEventListener("fpAuthStatus", fpAuthStatusHandler);
      document.removeEventListener("fp_user_event", fpUserEventHandler);
    };
  }, []);

  useEffect(() => {
    if (userId && userSignedIn && urlPath === "/app") refreshSubscriptionData();
  }, [userId, userSignedIn]);

  useEffect(() => {
    if (userLoaded && !userSignedIn && !pageIsPublic) {
      router.push("/");
    }
  }, [userLoaded, userSignedIn]);

  if (!userLoaded || (userLoaded && !userSignedIn && !pageIsPublic)) {
    return (
      <div className="flex h-[calc(100dvh-40px)] items-center justify-center dark:bg-dark-background">
        <LoadingCircle size="large" />
      </div>
    );
  }

  return (
    <>
      <NoSsrWrapper>
        <SideMenu />
      </NoSsrWrapper>

      {children}
    </>
  );
}
