import { SignOutIcon } from "@shared/icons";
import { SideMenuButton } from "@shared/ui";

export const LogOut = () => {
  const logout = () => {
    document.cookie = "fp.user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem("fp.user");
    localStorage.removeItem("fp.browser_session_id");
    localStorage.removeItem("fp.subscriber");
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <SideMenuButton
      data-testid="log-out-button"
      icon={<SignOutIcon />}
      label="Log Out"
      className="text-red-500 fp-sign-out"
      onClick={logout}
    />
  );
};
