import { toast } from "sonner";
import { fetchApi } from "@shared/lib";

export const deleteAllSavedLineups = async () => {
  try {
    const response = await fetchApi<never>(`/api/lineups/save`, { method: "DELETE" });
    if (response.message === "Success") {
      toast.success("All saved lineups have been cleared.");
    } else {
      toast.error("Failed to clear all saved lineups.");
    }
  } catch (error) {
    toast.error("An error occurred while clearing saved lineups.");
  }
};
