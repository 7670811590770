import { ClearChatHistory } from "@features/clearChatHistory";
import { LogOut } from "@features/logOut";
import { OpenProfile } from "@features/openUserCenter";
import { SideMenuButton } from "@shared/ui";
import { ClipboardIcon } from "@shared/icons";
import { BeakerIcon, HomeIcon, LockClosedIcon, QuestionMarkCircleIcon, StarIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";
import { useGeneral } from "@entities/general";

export const Settings = () => {
  const router = useRouter();
  const setShowSideMenu = useGeneral((state) => state.setShowSideMenu);

  return (
    <div className="flex-1 overflow-y-auto mb-3">
      <SideMenuButton
        icon={<HomeIcon className="w-[20px]" />}
        label="Home"
        onClick={() => {
          setShowSideMenu(false);
          router.push("/app");
        }}
      />
      <SideMenuButton
        icon={<BeakerIcon className="w-[20px]" />}
        label="About"
        onClick={() => {
          setShowSideMenu(false);
          router.push("/about");
        }}
      />
      <SideMenuButton
        icon={<QuestionMarkCircleIcon className="w-[20px]" />}
        label="FAQ"
        onClick={() => {
          setShowSideMenu(false);
          router.push("/faq");
        }}
      />
      <SideMenuButton
        icon={<StarIcon className="w-[20px]" />}
        label="Pro Tips"
        onClick={() => {
          setShowSideMenu(false);
          router.push("/pro-tips");
        }}
      />
      <SideMenuButton
        icon={<ClipboardIcon />}
        label="Terms of Service"
        onClick={() => {
          setShowSideMenu(false);
          router.push("/terms-of-service");
        }}
      />
      <SideMenuButton
        icon={<LockClosedIcon className="w-[20px]" />}
        label="Privacy Policy"
        onClick={() => {
          setShowSideMenu(false);
          router.push("/privacy-policy");
        }}
      />

      <hr className="mt-2 border-gray-600" />
      <ClearChatHistory />
      <OpenProfile />
      <LogOut />
    </div>
  );
};
