"use client";

import { ProfileIcon } from "@shared/icons";
import { SideMenuButton } from "@shared/ui";

import { useGeneral } from "@entities/general";

export const OpenProfile = () => {
  const setShowSideMenu = useGeneral((state) => state.setShowSideMenu);

  const onClick = () => {
    // FlipPay events are unreliable, therefore we do a full page replace, instead of a virtual one
    window.location.replace("/subscriptions");
    setShowSideMenu(false);
  };

  return <SideMenuButton onClick={onClick} icon={<ProfileIcon />} label="Manage Subscription" />;
};
