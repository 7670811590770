import clsx from "clsx";
import { Subscribe } from "@features/subscribe";
import { useUserStore } from "@entities/user";
import { memo } from "react";

export const Status = memo(function Status() {
  const [subscriptionType, availableLineups, availableMessages] = useUserStore((state) => [
    state.subscription?.type,
    state.subscription?.consumables.lineups ?? 0,
    state.subscription?.consumables.messages ?? 0,
  ]);

  const maxChatTokens = subscriptionType === "premium" ? 500 : subscriptionType === "elite" ? 5000 : 10;
  const chatProgressWidth = `${(availableMessages / Math.max(maxChatTokens, availableMessages)) * 100}%`;

  return (
    <div className="rounded-[10px] bg-[#272727] p-3">
      <p className="non-selectable text-gray-400">
        <span className="text-[26px] text-white">{availableMessages}</span>
        {availableMessages === 1 ? " mesage" : " messages"} left
      </p>

      <div className="relative mb-[20px] mt-[10px] h-1 w-full rounded-full bg-[#4D4D4D]">
        <div
          className={clsx(
            "absolute left-0 top-0 h-1 rounded-full",
            availableMessages > maxChatTokens / 2 ? "bg-[#139FEE]" : "bg-red-500"
          )}
          style={{ width: chatProgressWidth }}
        ></div>
      </div>
      {subscriptionType === "trial" && (
        <>
          <p className="non-selectable text-gray-400">
            <span className="text-[26px] text-white">{availableLineups}</span>
            {availableLineups === 1 ? " lineup" : " lineups"} left
          </p>

          <div className="relative mb-[20px] mt-[10px] h-1 w-full rounded-full bg-[#4D4D4D]">
            <div
              className={clsx(
                "absolute left-0 top-0 h-1 rounded-full",
                availableLineups > 5 ? "bg-[#139FEE]" : "bg-red-500"
              )}
              style={{ width: `${(availableLineups / Math.max(10, availableLineups)) * 100}%` }}
            ></div>
          </div>
        </>
      )}
      {subscriptionType === null && <Subscribe />}
    </div>
  );
});
