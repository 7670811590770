import { SideMenuButton } from "@shared/ui";
import { deleteAllSavedLineups } from "../../api";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useGeneral } from "@entities/general";

export const ClearSavedLineups = () => {
  const setSavedLineups = useGeneral((state) => state.setSavedLineups);

  const onClick = async () => {
    const confirmed = confirm("Are you sure you want to clear all saved lineups?");

    if (!confirmed) {
      return;
    }

    await deleteAllSavedLineups()
      .then(() => setSavedLineups([]))
      .catch((error) => console.error("Error clearing saved lineups:", error))
  };

  return (
    <SideMenuButton
      icon={<TrashIcon className="h-5" />}
      label="Clear All Saved Lineups"
      onClick={onClick}
      className="w-full text-red-500"
    />
  );
};
