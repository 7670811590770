import clsx from "clsx";
import { useState, memo } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { useGeneral } from "@entities/general";
import { useUserStore } from "@entities/user";

import { SavedLineups } from "../SavedLineups/SavedLineups";
import { Settings } from "../Settings/Settings";
import { Status } from "../Status/Status";

export const SideMenu = memo(function SideMenu() {
  const showSideMenu = useGeneral((state) => state.showSideMenu);
  const setShowSideMenu = useGeneral((state) => state.setShowSideMenu);

  const userName = useUserStore((state) => state.userName);
  const userEmail = useUserStore((state) => state.userEmail);

  const [currentSection, setCurrentSection] = useState<"savedLineups" | "settings">("settings");

  const onSideMenuKeyPress = (e: React.KeyboardEvent<HTMLMenuElement>) => {
    if (e.key === "Escape") {
      setShowSideMenu(false);
    }
  };

  return (
    <>
      {/* Gray Filter */}
      <div
        onClick={() => {
          setShowSideMenu(false);
        }}
        className={clsx(
          "duration-400 absolute left-0 top-0 z-40 h-full w-full  bg-gray-500 transition-all",
          showSideMenu ? "opacity-50" : "pointer-events-none opacity-0"
        )}
      ></div>

      <menu
        className={clsx(
          `duration-400 absolute left-0 top-0 z-50 h-full w-[320px] bg-gray-850 text-white transition-all lg:w-[400px]`,
          showSideMenu ? " left-0" : "left-[-320px] lg:-left-[400px]"
        )}
        onKeyDown={onSideMenuKeyPress}
      >
        <div className="h-full max-h-[100dvh] w-full flex flex-col px-4 py-3 lg:px-7 lg:py-[30px]">
          <button
            aria-label="close side menu"
            className="flex items-center gap-2"
            onClick={() => setShowSideMenu(false)}
          >
            <XMarkIcon className="h-10 text-gray-500" />
            <span className="text-gray-400">Close menu</span>
          </button>

          <div className="mt-5">
            <h3 className="lg:text-2xl font-bold truncate">{userName}</h3>
            <p className="text-gray-400">{userEmail}</p>
          </div>

          <div className="relative my-5 flex w-full rounded-[8px] bg-[#383838] p-[5px]">
            <div
              className={clsx(
                "absolute left-[5px] top-[5px] z-10 rounded-[6px] bg-white transition-all duration-300",
                currentSection === "settings" ? "translate-x-0" : "translate-x-full"
              )}
              style={{ height: "calc(100% - 10px)", width: "calc(50% - 5px)" }}
            ></div>

            <button
              onClick={() => setCurrentSection("settings")}
              className={clsx(
                "text-xs sm:text-sm z-20 w-full rounded-[6px] bg-transparent p-2 text-black",
                currentSection === "settings" ? "font-medium text-black" : "font-normal text-white"
              )}
            >
              Menu
            </button>
            <button
              onClick={() => setCurrentSection("savedLineups")}
              className={clsx(
                "text-xs sm:text-sm z-20 w-full rounded-[6px] bg-transparent p-2 text-black",
                currentSection === "savedLineups" ? "font-medium text-black" : "font-normal text-white"
              )}
            >
              Saved Lineups
            </button>
          </div>
          {currentSection === "savedLineups" ? <SavedLineups /> : <Settings />}
          <Status />
        </div>
      </menu>
    </>
  );
});
