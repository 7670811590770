import { Button } from "@shared/ui";
import { useGeneral } from "@entities/general";
import { TrophyIcon } from "@heroicons/react/24/outline";

export const Subscribe = () => {
  const setShowSideMenu = useGeneral((state) => state.setShowSideMenu);

  const onClickSubscribe = () => {
    setShowSideMenu(false);
    document.dispatchEvent(new CustomEvent("fpShowPriceOverlay", { bubbles: false, detail: { type: "dfspro-trial" } }));
  };

  return (
    <Button variant="white" className="w-full justify-center" onClick={onClickSubscribe}>
      <TrophyIcon className="w-5 text-black dark:text-dark-text" />
      Subscribe
    </Button>
  );
};
