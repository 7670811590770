import { useGeneratedLineup } from "@entities/generatedLineup";
import { NflBallIcon } from "@shared/icons";
import { ConfirmModal, SideMenuButton } from "@shared/ui";
import { useState } from "react";
import { toast } from "sonner";

type LoadSavedLineupProps = {
  savedLineup: Api.SavedLineups[number];
  onClickSavedLineup: () => void;
};

export const LoadSavedLineup: React.FC<LoadSavedLineupProps> = ({ savedLineup, onClickSavedLineup }) => {
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const lineups = useGeneratedLineup((state) => state.lineups);

  const onClickSave = () => {
    const saveLineupButtonElement = document.getElementById("save-lineup-button");
    if (saveLineupButtonElement) {
      setDiscardModalIsOpen(false);
      saveLineupButtonElement.click();
    } else {
      setDiscardModalIsOpen(false);
      toast.message("Press the 'Save' button to save your lineup");
    }
  };

  const onClickExport = () => {
    const exportLineupButtonElement = document.getElementById("export-lineup-button");
    if (exportLineupButtonElement) {
      setDiscardModalIsOpen(false);
      exportLineupButtonElement.click();
    } else {
      setDiscardModalIsOpen(false);
      toast.message("Press the 'Export' button to export your lineup");
    }
  };

  const handleOpenSavedLineup = () => {
    lineups.length > 0 ? setDiscardModalIsOpen(true) : onClickSavedLineup();
  };

  return (
    <>
      <SideMenuButton icon={<NflBallIcon />} label={savedLineup.name} onClick={handleOpenSavedLineup} />
      <ConfirmModal
        title="New Lineup"
        description={
          <>
            Loading a saved lineup will discard your current changes. Are you sure? This action cannot be undone. You
            can also{" "}
            <span className="underline cursor-pointer hover:text-primary" onClick={onClickSave}>
              save
            </span>{" "}
            or{" "}
            <span className="underline cursor-pointer hover:text-primary" onClick={onClickExport}>
              export
            </span>{" "}
            your lineup to access it later.
          </>
        }
        isOpen={discardModalIsOpen}
        onConfirm={() => {
          onClickSavedLineup();
          setDiscardModalIsOpen(false);
        }}
        onClose={() => setDiscardModalIsOpen(false)}
        onCancel={() => setDiscardModalIsOpen(false)}
      />
    </>
  );
};
